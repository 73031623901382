import React, { Suspense, lazy, Fragment} from "react";
import { Route, Switch } from "react-router-dom";
import DisplayAll from './components/gaurds/DisplayAll'
import Departments from './components/gaurds/Departments'

export const renderRoutes = (routes) => {
  return(
    <Suspense fallback={<div>Please Wait....</div>}>
      <Switch>
        {routes.map( (route, index) => {
          const Component = route.component
          const Gaurd = route.gaurd || Fragment
          return(
            <Route
            key={index}
            exact={route.exact}
            path={route.path}
            render={ (props) => (
              <Gaurd>
                <Component {...props} />
              </Gaurd>
            )}
            />
          )
        } )}
      </Switch>
    </Suspense>
  )
}

export const routes = [
  {
    exact: true,
    path: '/',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/home/Home'))
  },
  {
    exact: true,
    path: '/admission',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/admissions/Admission'))
  },
  {
    exact: true,
    path: '/department/cse',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/cse'))
  },
  {
    exact: true,
    path: '/department/ece',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/ece'))
  },
  {
    exact: true,
    path: '/department/eee',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/eee'))
  },
  {
    exact: true,
    path: '/department/ce',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/ce'))
  },
  {
    exact: true,
    path: '/department/it',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/it'))
  },
  {
    exact: true,
    path: '/department/me',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/Home'))
  },
  {
    exact: true,
    path: '/department/ice',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/ice'))
  },
  {
    exact: true,
    path: '/department/mba',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/mba'))
  },
  {
    exact: true,
    path: '/department/mca',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/mca'))
  },
  {
    exact: true,
    path: '/department/ee',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/ee'))
  },
  {
    exact: true,
    path: '/department/phy',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/physics'))
  },
  {
    exact: true,
    path: '/department/mat',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/maths'))
  },
  {
    exact: true,
    path: '/department/eng',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/english'))
  },
  {
    exact: true,
    path: '/department/chem',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/chemistry'))
  },
  {
    exact: true,
    path: '/institute',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/institute/Institute'))
  },
  {
    exact: true,
    path: '/visionmission',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/visionMission/VisionMission'))
  },
  {
    exact: true,
    path: '/principalmessage',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/principalMessage/PrincipalMessage'))
  },
  {
    exact: true,
    path: '/governingcounsil',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/governingCounsil/GoverningCounsil'))
  },
  {
    exact: true,
    path: '/importantfunctionaries',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/importantFunctionaries/ImportantFunctionaries'))
  },
  {
    exact: true,
    path: '/mandatorydisclosure',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/mandatoryDisclosure/MandatoryDisclosure'))
  },
  {
    exact: true,
    path: '/antiragging',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/AntiRagging/AntiRagging'))
  },
  {
    exact: true,
    path: '/staffdirectory',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/staffDirectory/StaffDirectory'))
  },
  {
    exact: true,
    path: '/awards',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/awards/Awards'))
  },
  {
    exact: true,
    path: '/accreditation',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/about/accreditation/Accreditation'))
  },
  {
    exact: true,
    path: '/campusFacilities/hostelFacilities',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/hostelFacilities/HostelFacilities'))
  },
  {
    exact: true,
    path: '/campusFacilities/computerCentre',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/computerCentre/ComputerCentre'))
  },
  {
    exact: true,
    path: '/campusFacilities/library',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/library/Library'))
  },
  {
    exact: true,
    path: '/campusFacilities/medicalFacilities',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/medicalFacilities/MedicalFacilities'))
  },
  {
    exact: true,
    path: '/campusFacilities/mess',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/mess/Mess'))
  },
  {
    exact: true,
    path: '/campusFacilities/mph',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/mph/Mph'))
  },
  {
    exact: true,
    path: '/campusFacilities/sports',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/campusFacilities/sports/Sports'))
  },
  {
    exact: true,
    path: '/training&Placement/overview',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/training&Placement/overview/Overview'))
  },
  {
    exact: true,
    path: '/training&Placement/tpoMessage',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/training&Placement/tpoMessage/TpoMessage'))
  },
  {
    exact: true,
    path: '/training&Placement/recruits',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/training&Placement/recruits/Recruits'))
  },
  {
    exact: true,
    path: '/training&Placement/policy',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/training&Placement/policy/Policy'))
  },
  {
    exact: true,
    path: '/training&Placement/placedStatistics',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/training&Placement/placedStatistics/PlacedStatistics'))
  },
  {
    exact: true,
    path: '/department/me/about/history',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/VisionMission'))
  },
  {
    exact: true,
    path: '/department/me/about/visionmission',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/Histroy'))
  },
  {
    exact: true,
    path: '/department/me/about/hod',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/HoDs'))
  },
  {
    exact: true,
    path: '/department/me/about/ranks&awards',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/RanksAwards'))
  },
  {
    exact: true,
    path: '/department/me/about/accrediation',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/Accrediation'))
  },
  {
    exact: true,
    path: '/department/me/about/contact',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/about/Contact'))
  },
  {
    exact: true,
    path: '/department/me/academics/calendar',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/academics/Calendar'))
  },
  {
    exact: true,
    path: '/department/me/academics/committee',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/academics/Commitee'))
  },
  {
    exact: true,
    path: '/department/me/academics/programs',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/academics/UGProgram'))
  },
  {
    exact: true,
    path: '/department/me/academics/facilities',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/academics/Facility'))
  },
  {
    exact: true,
    path: '/department/me/academics/results',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/academics/UniversityResults'))
  },
  {
    exact: true,
    path: '/department/me/faculty',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/faculty/Faculty'))
  },
  {
    exact: true,
    path: '/department/me/faculty/:faculty_name',
    gaurd: Departments,
    component: lazy(() => import('./components/departments/me/faculty/singleFacultyDetails'))
  },


  {
    exact: true,
    path: '/nirf/:nirf_year',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/nirf/Nirf'))
  },
  {
    exact: true,
    path: '/library',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/Library/Library'))
  },
  {
    exact: true,
    path: '/iic',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/iic/IIC'))
  },
  {
    exact: true,
    path: '/step',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/step/Step'))
  },
  {
    exact: true,
    path: '/dashboard',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/dashboard/Dashboard'))
  },
  {
    exact: true,
    path: '/collegebrochure',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/collegeBrochure/CollegeBrochure'))
  },
  {
    exact: true,
    path: '/contactus',
    gaurd: DisplayAll,
    component: lazy(() => import('./components/headNavbar.js/contactUs/ContactUs'))
  }
]



// {
//   exact: true,
//   path: '/',
//   gaurd: ,
//   component: lazy(() => import(''))
// },

//       </Switch>
//       <Footer />
//     </>
//   );
// }

// export default Router;
