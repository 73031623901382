import React from 'react'
import { withRouter } from 'react-router-dom'
import './navbar.css'

function NavbarComponent5(props) {
    let arr = props.match.url.slice(1).toUpperCase().split('/')
    return (
        <>
            { arr[0] === '' ? (<></>) : (
                <nav className="container-fluid bg-tertiaryColor py-4">
                    <div className="container text-secondaryColor font-regular font-16 d-flex px-2">
                        {arr.map( (str, index) => {
                            return( 
                                <div id="nav5" className="text-secondaryColor font-regular font-16 letter-spacing-2 px-3 border-left-nav" key={index}>
                                    {str}
                                </div>
                            )
                        } )}
                    </div>
                </nav>
            )}
        </>
    )
}

export default withRouter(NavbarComponent5)
