import React from 'react'
import NavbarComponent from '../navbar/NavbarComponent'
import NavbarComponent4 from '../navbar/NavbarComponent4'
import NavbarComponent5 from '../navbar/NavbarComponent5'
import Footer from '../footer/Footer'

function Departments({children}) {
    return (
        <div>
            <NavbarComponent />
            <NavbarComponent4 />
            <NavbarComponent5 />
            {children}
            <Footer />
        </div>
    )
}

export default Departments
