import React from "react";
import NavbarComponent1 from "./NavbarComponent1";
import NavbarComponent2 from "./NavbarComponent2";
// import NavbarComponent3 from "./NavbarComponent3";

function NavbarComponent(){
    return(
        <>  
            <NavbarComponent1 />
            <NavbarComponent2 />
            {/* <NavbarComponent3 /> */}
        </>
    );  
}

export default NavbarComponent;