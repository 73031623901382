import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Logo from "../.././images/home/logoSmall.png";
import { Link } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./navbar.css";

function NavbarComponent4() {
    return (
      <div className="text-light">
        <Navbar expand="xl" variant="light" className="bg-secondaryColor">
          <Nav className="container-fluid font-12 text-nowrap text-center mx-5" defaultActiveKey="/">
            
            <NavDropdown
              title="Home"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                    JSSATEN HOME
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/department/me"
                >
                  ME HOME
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="ABOUT"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/about/history"
                >
                  HISTORY
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/about/visionmission"
                >
                  VISION &amp; MISSION
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/about/hod"
                >
                  HOD'S MESSAGE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/about/ranks&amp;awards"
                >
                  RANKS &amp; AWARDS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/about/accrediation"
                >
                  ACCREDIATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/department/me/about/contact"
                >
                  CONTACT
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="ACADEMICS"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">                
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/academics/calendar"
                >
                  ACADEMIC CALENDER
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/academics/committee"
                >
                  ACADEMIC COMMITTEE
                </NavDropdown.Item>
                    <div className="mb-0">
                        <DropdownButton
                            key="right"
                            id="dropdown-button-drop-right"
                            variant=""
                            drop="right"
                            title="PROGRAMS"
                            className="font-regular font-12 dropdown-item-bottom"
                        >
                            <div className="bg-secondaryColor text-white font-12 py-2">
                            <NavDropdown.Item
                                className="text-white font-regular font-12 py-2 dropdown-item"
                                  as={Link}
                                  to="/department/me/academics/programs"
                                >
                                UNDERGRADUATE PROGRAM
                            </NavDropdown.Item>
                            </div>
                        </DropdownButton>
                    </div>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/academics/facilities"
                >
                  FACILITIES
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/department/me/academics/results"
                >
                  UNIVERSITY RESULTS
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="RESEARCH"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-12 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  RESEARCH
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  RESEARCH &amp; DEVELOPMENT
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  CHAPTER/MONOGRAM
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  PATENT
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  FDP
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                 PUBLICATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                //   as={Link}
                //   to="/"
                >
                  PROJECTS
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="CENTER OF EXCELLENCE"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/training&amp;Placement/overview"
                >
                  COE &amp; MOU
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                //   as={Link}
                //   to="/training&amp;Placement/placedStatistics"
                >
                    MOUS
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="FACULTY"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/department/me/faculty"
                >
                  FACULTY
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  ADJUNCT FACULTY
                </NavDropdown.Item>
                <NavDropdown.Item 
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/"
                >
                 STAFF
                </NavDropdown.Item> */}
              </div>
            </NavDropdown>

            <NavDropdown
              title="NEWS &amp; EVENTS"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  NEWS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  EVENTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  FACULTY AWARDS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  STUDENTS AWARDS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  WORKSHOPS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                //   as={Link}
                //   to="/"
                >
                  NEWS LETTERS
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="FACULTY"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  DEAN RESEARCH
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  FACULTY DEVELOPMENT PROGRAM
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  PUBLICATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  RESEARCH PROJECTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  PATENTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  CHAPTER/MONOGRAM
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  WORKSHOPS/SEMINAR
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  STUDENT PROJECTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  STUDENT PROJECTS VIDEOS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                  as={Link}
                  to="/"
                >
                  RESEARCH &amp; DEVELOPMENT
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="ENTERPRENEURSHIP"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                //   as={Link}
                //   to="/"
                >
                  ENTERPRENEURS
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown
              title="PLACEMENT"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                 PLACEMENT YEAR WISE DETAILS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                //   as={Link}
                //   to="/"
                >
                  HIGHER STUDIES
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="ALUMNI"
              className="my-0"
              id="dept-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                //   as={Link}
                //   to="/"
                >
                  ALUMNI
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                //   as={Link}
                //   to="/"
                >
                  ILLUSTRIOUS ALUMNI
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          </Nav>
        </Navbar>
      </div>
    );
}

export default NavbarComponent4;
