import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { renderRoutes, routes} from "./Router"; 

function App() {
    return (
      <div className="App">
        {renderRoutes(routes)}
      </div>
    );
}

export default App;
